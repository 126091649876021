<template>
  <div class="my-login-page">
    <section class="h-100">
      <div class="container h-100">
        <div class="row justify-content-md-center h-100">
          <div class="card-wrapper">
            <div class="brand">
              <img src="@/assets/uib_logo.png">
            </div>
            <div class="card fat">
              <div class="card-body">
                <h4 class="card-title text-center">Регистрация в <strong>UIB</strong></h4>
                <form v-on:submit.prevent="submitForm" class="my-login-validation" novalidate="">

                  <div class="form-group col-md-12 mt-4">
                    <label for="lastname">Фамилия на английском</label>
                    <input id="lastname" type="text" class="form-control" name="lastname" v-model="form.lastname"
                           required autofocus>
                    <div class="invalid-feedback">
                      Введите фамилию
                    </div>
                  </div>

                  <div class="form-group col-md-12 mt-4">
                    <label for="firstname">Имя на английском</label>
                    <input id="firstname" type="text" class="form-control" name="firstname" v-model="form.firstname"
                           required>
                    <div class="invalid-feedback">
                      Введите имя
                    </div>
                  </div>

                  <div class="form-group col-md-12 mt-4">
                    <label for="middlename">Отчество на английском</label>
                    <input id="middlename" type="text" class="form-control" name="middlename" v-model="form.middlename">
                    <div class="invalid-feedback">
                      Введите Отчество
                    </div>
                  </div>

                  <div class="form-group col-md-12 mt-4">
                    <label for="email">Личный e-mail</label>
                    <input id="email" type="email" class="form-control" name="email" v-model="form.email" required>
                    <div class="invalid-feedback">
                      Email неправильный
                    </div>
                  </div>

                  <div class="password-div form-group col-md-12 mt-4">
                    <label for="password" class="d-inline">Пароль </label>
                    <i class="fas fa-question d-inline" id="password_info"></i>
                    <div class="alert alert-info password-info" role="alert">
                      <p>Требования к паролю:</p>
                      <p> Не менее 8 символов' </p>
                      <p> Хотя бы одна заглавная буква' </p>
                      <p>Хотя бы одна строчная буква' </p>
                      <p>Хотя бы одна цифра.'</p>
                    </div>
                    <i class="fas fa-eye show-icon password-eye" v-if="show" @click="show = !show"></i>
                    <i class="fas fa-eye-slash hide-icon password-eye" v-else @click="show = !show"></i>
                    <input :type="show === true ? 'text':'password'" id="password" class="form-control" name="password"
                           v-model="form.password" required data-eye>
                    <div class="password-checker-info text-center">
                      <span v-if="scorePassword <= 25 && scorePassword > 0" class="text-danger">{{
                          passwordProtection
                        }}</span>
                      <span v-else-if="scorePassword < 75 && scorePassword > 25"
                            class="bg-light text-danger">{{ passwordProtection }}</span>
                      <span v-else-if="scorePassword >= 75" class="text-success">{{ passwordProtection }}</span>
                      <span v-else class="text-info">{{ passwordProtection }}</span>

                    </div>
                    <div class="invalid-feedback">
                      Введите пароль
                    </div>
                  </div>

                  <div class="form-group col-md-12 mt-4">
                    <label for="password_repeat">Повторите пароль</label>
                    <input id="password_repeat" type="password" class="form-control" name="password_repeat"
                           v-model="form.password_repeat" required data-eye>
                    <div class="invalid-feedback">
                      Введите пароль еще раз
                    </div>
                  </div>

                  <div class="form-group col-md-12 mt-4">
                    <div class="custom-checkbox custom-control">
                      <input type="checkbox" id="agree" v-model="agree">
                      <span for="agree" class="custom-control-label"> Нажимая кнопку «Регистрация», я даю свое согласие
                        на обработку моих персональных данных, в соответствии с Законом Республики Казахстана&nbsp;</span>
                      <span v-if="showMore" class="more-text"><a href="https://back.uib.kz/main-files/register_agreement.docx">
                        от 21 мая 2013года № 94-V О персональных данных и их защите, на условиях и для целей,
                        определенных в Согласии на сбор и обработку персональных данных</a>
                      </span>
                      <span v-else class="more-link" @click="toggleMore">показать больше...</span>
                      <div class="invalid-feedback">
                        Вы должны согласится с условиями
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-md-12 mt-4">
                    <button :disabled="disabledCheck===false" class="btn btn-primary btn-block reg-btn">
                      {{ loadingText }}
                    </button>
                  </div>
                  <div class="mt-4 text-center">
                    Уже есть аккаунт?
                    <router-link to="/login" class="forgot-pass">Войти</router-link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import axios from 'axios'
import httpClient from "../services/http.service";

export default {
  name: 'Form',
  data() {
    return {
      form: {
        firstname: '',
        lastname: '',
        middlename: '',
        email: '',
        password: '',
        password_repeat: ''
      },
      show: false,
      agree: false,
      loading: false,
      showMore: false
    }
  },
  methods: {
    async submitForm() {
      try {
        this.loading = true
        const {status} = await httpClient.post('api/user/signup', this.form);
        if (status === 200) {
          this.$message({title: 'Регистрация', text: 'Регистрация прошла успешно, проверьте свою почту'})

          this.clearForm()
          setTimeout(() => {
            this.$router.push('login');
          }, 500);

        }
      } catch (e) {
        this.$error({title: 'Ошибка регистрации', text: JSON.stringify(Object.values(e.response.data.errors))})
      } finally {
        this.loading = false
      }

    },
    clearForm() {
      this.form.firstname = this.form.lastname = this.form.middlename = this.form.email = this.form.password = this.form.password_repeat = ''
    },
    toggleMore() {
      this.showMore = !this.showMore;
    }
  },
  computed: {
    scorePassword() {
      let score = 0;
      if (this.form.password === '') return score;
      let letters = {};
      for (let i = 0; i < this.form.password.length; i++) {
        letters[this.form.password[i]] = (letters[this.form.password[i]] || 0) + 1;
        score += 6.0 / letters[this.form.password[i]];
      }
      let variations = {
        digits: /\d/.test(this.form.password),
        lower: /[a-z]/.test(this.form.password),
        upper: /[A-Z]/.test(this.form.password),
        special: /\W/.test(this.form.password)
      };
      let variationsCount = 0;
      for (let check in variations) {
        variationsCount += (variations[check] === true) ? 1 : 0;
      }
      score += (variationsCount - 1) * 12;
      return parseInt(score);
    },
    passwordProtection() {
      if (this.scorePassword < 75 && this.scorePassword > 0) {
        return 'Пароль не соответствует требованиям безопасности'
      }
      if (this.scorePassword >= 75) {
        return 'Хороший пароль'
      } else {
        return ''
      }
    },
    disabledCheck() {
      return this.scorePassword >= 75 && this.agree === true && this.loading === false;
    },
    loadingText() {
      if (this.loading === true) {
        return 'Ожидайте...'
      } else {
        return 'Регистрация'
      }
    }
  }
}

</script>

<style lang="css" scoped>
.more-link {
  color: blue;
  cursor: pointer;
}
.password-eye {
  /*position: absolute;*/
  /*top: 6px;*/
  position: relative;
  top: 32px;
  right: 52px;
  z-index: 11;
  cursor: pointer;
}

input#password {
  padding-left: 30px;
}

small {
  display: block;
  color: #888;
}

p {
  margin-bottom: 2px;
}

#password_info:hover + .password-info {
  display: block;
  position: absolute;
  top: 35%; /* Абсолютное позиционирование */
  width: 80%;
  z-index: 15;

}

.password-info {
  display: none;
}

.password-checker-info {
  display: block;
  position: absolute;
}
</style>
